<template>
  <div class="email-upload">
    <div class="email-upload-header">
      <div class="email-upload-header__file-uploader">
        <FileUpload
          name="demo[]"
          accept=".pdf"
          :maxFileSize="10000000"
          :chooseLabel="Choose"
          uploadLabel="Upload"
          cancelLabel="Cancel"
          :disabled="disabled"
          :showCancelButton="false"
          :showUploadButton="false"
          @select="handleSelect"
        >
        </FileUpload>
      </div>
      <div class="email-upload-header__load-info">
        <ShpSearch @shpSend="shpReceived" :load="load" />
      </div>
    </div>
    <div class="email-upload-icon">
      <div
        v-for="(images, index) in file"
        :key="'images.name' + index"
        class="email-upload__image"
      >
        <img
          v-if="file[index].type.search('image') !== -1"
          src="@/assets/images/logo-image.png"
          @click="showPdf(index)"
          alt=""
        />
        <img
          v-else
          src="@/assets/images/logo-pdf.png"
          @click="showPdf(index)"
          alt=""
        />
        <b-dropdown
          size="sm"
          toggle-class="text-decoration-none"
          dropup
          no-caret
          variant="link"
          class="m-2 email-upload__file-options"
          @toggle="deleteControl(index)"
        >
          <template #button-content>
            <i class="fas fa-edit" style="margin: -3px"></i>
          </template>
          <b-dropdown-item-button
            :disabled="disabled"
            @click="removeFile(index)"
            >Delete</b-dropdown-item-button
          >
          <b-dropdown-item-button @click="downloadFile(images)"
            >Download</b-dropdown-item-button
          >
          <b-dropdown-item-button
            @click="fileTransfer(index, option)"
            :key="index + option"
            v-for="option in options"
            >{{ option }}</b-dropdown-item-button
          >
        </b-dropdown>
        <p class="email-upload__name">{{ file[index].name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import FileUpload from "primevue/fileupload";
import ShpSearch from "../ShpSearch.vue";

export default {
  name: "EmailUpload",
  props: ["tabSelected", "load", "images"],
  components: {
    FileUpload,
    ShpSearch,
  },
  data() {
    return {
      numberOfTabs: 1,
      filteredShp: null,
      file: [],
      infoFileFromEmail: null,
      indexFile: 0,
      options: [],
      tabNumber: this.load,
      filesCreated: [],
      isDisabled: true,
      emailId: null,
      thereAreFiles: false,
    };
  },
  computed: {
    ...mapState({
      showEmails: (state) => state.emails.showEmails,
      loadOptions: (state) => state.dbFile.loadsOptions,
    }),
    disabled() {
      return this.isDisabled;
    },
    Choose() {
      return "Add Files";
    },
    Cancel() {
      return "Cancel";
    },
    Upload() {
      return "Upload";
    },
  },
  watch: {
    async images() {
      if (this.load === this.tabSelected) {
        let loadsInfo = this.$store.getters["dbLoads/showLoadIdInfo"];
        let loadId;
        for (let load of loadsInfo) {
          if (load.tab == this.tabSelected) {
            loadId = load.id;
          }
        }
        let images = [];
        let paths = this.$store.getters["dbFile/showDbFile"].map(
          (el) => el.path
        );
        for (let i in paths) {
          for (let j in this.images) {
            if (this.images[j] === paths[i]) {
              this.images.splice(j, 1);
            }
          }
        }
        if (this.images.length > 0) {
          for (let path of this.images) {
            let params = {
              path: path,
            };
            let response = await this.$store.dispatch(
              "bucket/getFilesFromBucket",
              params
            );
            response.path = path;
            images.push(response);
          }
          this.file = this.file.concat(
            this.$store.getters["bucket/showImages"]
          );
        }
        this.$emit("imagesLoaded");
        for (let file of images) {
          let body = {};
          body.name = file.name;
          body.email_id = this.emailId;
          body.load_id = loadId;
          body.pages = [
            {
              page: 1,
              type: "",
            },
          ];
          body.path = file.path;
          let response = await this.$store.dispatch("dbFile/createFiles", body);
          this.filesCreated.push(response);
        }
      }
    },
    async showEmails(state) {
      this.thereAreFiles = true;
      if (state == true) {
        this.$store.commit("dbFile/setCleanFiles");
        this.file = [];
        this.filesCreated = [];
        this.infoFileFromEmail = await this.$store.getters["dbFile/showDbFile"];
        this.emailId = this.infoFileFromEmail[0].email_id;
        for (let i in this.infoFileFromEmail) {
          this.filesCreated.push(this.infoFileFromEmail[i]);
          this.$store.commit(
            "dbFile/setCreateFiles",
            this.infoFileFromEmail[i]
          );
          let data = {};
          data.name = this.infoFileFromEmail[i].name;
          data.type = "pdf";
          data.path = this.infoFileFromEmail[i].path;
          if (data.path != null || data.path != "") {
            this.file.push(
              await this.$store.dispatch("bucket/getFilesFromBucket", data)
            );
          }
          let length = this.file.length;
          if (this.infoFileFromEmail[i].load_id) {
            let infoLoadById = this.$store.getters["dbLoads/showInfoLoadById"];
            for (let j in infoLoadById) {
              if (this.infoFileFromEmail[i].load_id == infoLoadById[j].id) {
                this.file[length - 1].tab = infoLoadById[j].shp;
              }
            }
          } else {
            let tabSelected = this.$store.getters["dbFile/showTabSelected"];
            this.file[length - 1].tab = tabSelected;
          }
          this.file[length - 1].id = this.infoFileFromEmail[i].id;
          this.$store.commit("dbFile/setHandleSelected", this.file[length - 1]);
        }
        this.thereAreFiles = false;
        this.$store.commit("bucket/setThereAreFilesFlag");
      }
    },
    tabSelected() {
      this.file = [];
      this.filesCreated = this.$store.getters["dbFile/showFilesCreated"];
      this.emailId = this.filesCreated[0].email_id;
      let tabSelected = this.$store.getters["dbFile/showTabSelected"];
      let optionsBeforeFilter = this.$store.getters["dbFile/showLoadsOptions"];
      this.options = optionsBeforeFilter.filter(
        (option) => option != tabSelected
      );
      let filesTransfer = this.$store.getters["dbFile/showFilesTransfer"];
      for (let i in filesTransfer) {
        if (filesTransfer[i].tab == tabSelected) {
          this.file.push(filesTransfer[i]);
        }
      }
      if (this.tabNumber === this.tabSelected) {
        if (this.fileIdIndex !== null && this.fileIdIndex !== undefined) {
          this.showPdf(this.fileIdIndex);
        }
      }
    },
    loadOptions(options) {
      let tabSelected = this.$store.getters["dbFile/showTabSelected"];
      let optionsBeforeFilter = options;
      this.options = optionsBeforeFilter.filter(
        (option) => option != tabSelected
      );
    },
  },
  methods: {
    deleteControl(index) {
      if (this.file[index].can_delete == false) {
        this.isDisabled = true;
      } else {
        this.isDisabled = false;
      }
    },
    async fileTransfer(index, tab) {
      this.file[index].tab = tab;
      this.$store.commit("dbFile/setHandleSelected", this.file[index]);
      this.file.splice(index, 1);
      this.indexFile -= 1;
      this.$emit("reloadPdfViewer");
    },
    newTab() {
      this.numberOfTabs += 1;
    },
    deleteTab() {
      this.numberOfTabs -= 1;
    },
    async handleSelect(event) {
      this.file.push(event.files[this.indexFile]);
      let length = this.file.length;
      let tabSelected = this.$store.getters["dbFile/showTabSelected"];
      this.file[length - 1].can_delete = true;
      this.file[length - 1].tab = tabSelected;
      this.$store.commit("dbFile/setHandleSelected", this.file[length - 1]);
      let body = {};
      body.name = this.file[length - 1].name;
      body.email_id = this.emailId;
      body.pages = [];
      this.indexFile += 1;
      await this.createFile(body);
    },
    downloadFile(file) {
      let blobUrl = URL.createObjectURL(file);
      let link = document.createElement("a");
      link.setAttribute("href", blobUrl);
      link.setAttribute("download", file.name);
      link.click();
    },
    removeFile(index) {
      for (let i in this.filesCreated) {
        if (this.file[index].name == this.filesCreated[i].name) {
          let idFileDelet = this.filesCreated[i].id;
          this.$store.commit("dbFile/deleteFiles", this.file[index]);
          this.$store.dispatch("dbFile/deleteFiles", idFileDelet);
          this.filesCreated.splice(i, 1);
        }
      }
      this.file.splice(index, 1);
      this.indexFile -= 1;
      this.$emit("reloadPdfViewer");
    },
    showPdf(index) {
      this.fileIdIndex = index;
      let idFileSelected;
      let infoEmailSelected;
      for (let i in this.filesCreated) {
        if (
          this.filesCreated[i].id == this.file[index].id ||
          this.filesCreated[i].name == this.file[index].name
        ) {
          idFileSelected = this.filesCreated[i].id;
          infoEmailSelected = this.filesCreated[i];
        }
      }
      this.$store.commit("dbFile/setEmailSelected", infoEmailSelected);
      this.$store.commit("dbFile/idFileSelected", idFileSelected);
      if (this.file[index].type.search("image") !== -1) {
        this.$emit("uploadFile", {
          src: this.file[index],
          type: "image",
        });
      } else {
        this.file[index].arrayBuffer().then((data) => {
          const uint8Arr = new Uint8Array(data);
          this.$emit("uploadFile", {
            src: uint8Arr,
            type: "PDF",
          });
        });
      }
    },
    async createFile(body) {
      let response = await this.$store.dispatch("dbFile/createFiles", body);
      this.filesCreated.push(response);
      let length = this.file.length;
      let bodySaveFilesBucket = {};
      bodySaveFilesBucket.file = this.file[length - 1];
      bodySaveFilesBucket.path = response.path;
      await this.$store.dispatch("bucket/saveFilesBucket", bodySaveFilesBucket);
    },
    async shpReceived(shpInfo) {
      if (shpInfo.shpCode != "" && shpInfo.id != "") {
        let body = {};
        body.shp = shpInfo.shpCode;
        body.salesforce_id = shpInfo.id;
        let createLoads = await this.$store.dispatch(
          "dbLoads/createLoads",
          body
        );
        let loadIdInfo = {};
        loadIdInfo.id = createLoads.id;
        loadIdInfo.tab = shpInfo.tabSelected;
        this.$store.commit("dbLoads/setLoadsInfo", loadIdInfo);
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-tab {
  @include close-button($color-white);
}

::v-deep .p-fileupload-content {
  display: none;
}

::v-deep .p-fileupload .p-fileupload-buttonbar {
  padding: 0;
  border: none;

  .p-button {
    @include primary-button;
  }
}

.email-upload {
  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    position: relative;
    width: 96px;
    padding: 5px 3px;
    margin: 20px 5px 0px 5px;
    cursor: pointer;

    &--selected {
      background: var(--secondary-gray-outline) 0% 0% no-repeat padding-box;
      border: 1px solid #c3c5c9;
      background: #f6f6f6 0% 0% no-repeat padding-box;
      opacity: 1;
    }

    &:not(:first-child) {
      margin-top: 14px;
    }

    & > img {
      width: 60px;
      object-fit: cover;
      margin-bottom: 10px;
    }

    & > span {
      overflow-wrap: break-word;
      width: 100%;
    }
  }

  &__file-options {
    position: absolute;
    top: -3px;
    right: -3px;
    margin: -5px;
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;

    & > img {
      width: 20px;
    }
  }
  &__name {
    overflow-wrap: anywhere;
  }
}

.email-upload-header {
  display: flex;
  flex-direction: row;

  &__load-info {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 20px;
  }
}

.email-upload-icon {
  display: flex;
  align-items: baseline;
}
</style>
