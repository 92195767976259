<template>
  <div class="manual-upload">
    <div class="manual-upload-header">
      <div class="manual-upload-header__file-uploader">
        <FileUpload
          name="demo[]"
          accept=".pdf"
          :maxFileSize="10000000"
          :chooseLabel="Choose"
          :disabled="disabled"
          uploadLabel="Upload"
          cancelLabel="Cancel"
          :showCancelButton="false"
          :showUploadButton="false"
          @select="handleSelect"
        >
        </FileUpload>
      </div>
      <div class="manual-upload-header__load-info">
        <ShpSearch @shpSend="shpReceived" />
      </div>
    </div>
    <div class="files-container">
      <div
        v-for="(images, index) in file"
        :key="'images.name' + index"
        class="manual-upload__image"
      >
        <img
          src="@/assets/images/logo-pdf.png"
          @click="showPdf(index)"
          alt=""
        />
        <b-dropdown
          size="sm"
          toggle-class="text-decoration-none"
          dropup
          no-caret
          variant="link"
          class="m-2 manual-upload__file-options"
        >
          <template #button-content>
            <i class="fas fa-edit" style="margin: -3px"></i>
          </template>
          <b-dropdown-item-button @click="removeFile(index)"
            >Delete</b-dropdown-item-button
          >
          <b-dropdown-item-button
            @click="fileTransfer(index, option)"
            :key="index + option"
            v-for="option in options"
            >Load {{ option }}</b-dropdown-item-button
          >
        </b-dropdown>
        <p class="manual-upload__name">{{ file[index].name }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import FileUpload from "primevue/fileupload";
import ShpSearch from "../ShpSearch.vue";
import { mapState } from "vuex";

export default {
  name: "ManualUpload",
  props: ["tabSelected", "optionsLoad", "load"],
  components: {
    FileUpload,
    ShpSearch
  },
  data() {
    return {
      files: [],
      fileUploadInstance: null,
      file: [],
      hasFiles: false,
      resetButton: false,
      IdCreateEmail: null,
      count: 0,
      filesCreated: [],
      isDisabled: true,
      options: [],
      fileIdIndex: null,
      tabNumber: this.load
    };
  },
  computed: {
    disabled() {
      return this.isDisabled;
    },
    Choose() {
      return "Add Files";
    },
    Cancel() {
      return "Cancel";
    },
    Upload() {
      return "Upload";
    },
    ...mapState({
      showEmails: state => state.emails.showEmails,
      showFilesTransfer: state => state.dbFile.files,
      loadOptions: state => state.dbFile.loadsOptions
    })
  },
  watch: {
    loadOptions(options) {
      let tabSelected = this.$store.getters["dbFile/showTabSelected"];
      let optionsBeforeFilter = options;
      this.options = optionsBeforeFilter.filter(
        option => option != tabSelected
      );
    },
    showEmails() {
      this.file = null;
    },
    tabSelected() {
      this.file = [];
      this.filesCreated = this.$store.getters["dbFile/showFilesCreated"];
      let infoEmail;
      if (this.$store.getters["dbFile/showFilesTransfer"].length > 0) {
        infoEmail = this.$store.getters["emails/showInfoEmail"];
        this.idCreateEmail = infoEmail.id;
      }
      let tabSelected = this.$store.getters["dbFile/showTabSelected"];
      let optionsBeforeFilter = this.$store.getters["dbFile/showLoadsOptions"];
      this.options = optionsBeforeFilter.filter(
        option => option != tabSelected
      );
      let filesTransfer = this.$store.getters["dbFile/showFilesTransfer"];

      for (let i in filesTransfer) {
        if (filesTransfer[i].tab == tabSelected) {
          this.file.push(filesTransfer[i]);
        }
      }
      if (this.tabNumber === this.tabSelected) {
        if (this.fileIdIndex !== null && this.fileIdIndex !== undefined) {
          this.showPdf(this.fileIdIndex);
        }
      }
    }
  },

  methods: {
    async handleSelect(event) {
      this.file.push(event.files[this.count]);
      let length = this.file.length;
      if (this.file.length == 1) {
        await this.createEmail();
      }
      let tabSelected = this.$store.getters["dbFile/showTabSelected"];
      this.file[length - 1].tab = tabSelected;
      this.$store.commit("dbFile/setHandleSelected", this.file[length - 1]);
      let body = {};
      body.name = this.file[length - 1].name;
      body.email_id = this.idCreateEmail;
      body.pages = [];
      this.count += 1;
      this.createFile(body);
    },
    removeFile(index) {
      for (let i in this.filesCreated) {
        if (this.file[index].name == this.filesCreated[i].name) {
          let idFileDelet = this.filesCreated[i].id;
          this.$store.commit("dbFile/deleteFiles", this.file[index]);
          this.$store.dispatch("dbFile/deleteFiles", idFileDelet);
          this.filesCreated.splice(i, 1);
        }
      }
      this.file.splice(index, 1);
      this.count -= 1;
      this.$emit("reloadPdfViewer");
    },
    async fileTransfer(index, tab) {
      this.file[index].tab = tab;
      this.$store.commit("dbFile/setHandleSelected", this.file[index]);
      this.file.splice(index, 1);
      this.count -= 1;
      this.$emit("reloadPdfViewer");
    },
    showPdf(index) {
      if (!this.isDisabled) {
        let idFileSelected;
        for (let i in this.filesCreated) {
          if (this.filesCreated[i].name == this.file[index].name) {
            idFileSelected = this.filesCreated[i].id;
          }
        }
        this.$store.commit("dbFile/idFileSelected", idFileSelected);
        this.file[index].arrayBuffer().then(data => {
          const uint8Arr = new Uint8Array(data);
          this.$emit("uploadFile", {
            src: uint8Arr,
            type: "PDF"
          });
          this.$emit("fileIdSelected", idFileSelected);
        });
        this.fileIdIndex = index;
      } else {
        this.swal({
          title: "There is something wrong",
          text: "Please enter the SHP code",
          icon: "error",
          dangerMode: true
        });
      }
    },
    async createEmail() {
      let response = await this.$store.dispatch("emails/createEmail");
      this.idCreateEmail = response.id;
    },
    async createFile(body) {
      let response = await this.$store.dispatch("dbFile/createFiles", body);
      this.filesCreated.push(response);
      let length = this.file.length;
      let bodySaveFilesBucket = {};
      bodySaveFilesBucket.file = this.file[length - 1];
      bodySaveFilesBucket.path = response.path;
      await this.$store.dispatch("bucket/saveFilesBucket", bodySaveFilesBucket);
    },
    async shpReceived(shpInfo) {
      if (shpInfo.shpCode != "" && shpInfo.id != "") {
        let body = {};
        body.shp = shpInfo.shpCode;
        body.salesforce_id = shpInfo.id;
        let createLoads = await this.$store.dispatch(
          "dbLoads/createLoads",
          body
        );
        let loadIdInfo = {};
        loadIdInfo.id = createLoads.id;
        loadIdInfo.tab = shpInfo.tabSelected;
        this.$store.commit("dbLoads/setLoadsInfo", loadIdInfo);
        this.isDisabled = false;
      } else {
        this.isDisabled = true;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .p-fileupload-content {
  display: none;
}

::v-deep .p-fileupload .p-fileupload-buttonbar {
  padding: 0;
  border: none;
  .p-button {
    @include primary-button;
  }
}

.manual-upload {
  &__image {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 13px;
    position: relative;
    width: 96px;
    padding: 5px 3px;
    margin-top: 20px;
    cursor: pointer;

    &--selected {
      background: var(--secondary-gray-outline) 0% 0% no-repeat padding-box;
      border: 1px solid #c3c5c9;
      background: #f6f6f6 0% 0% no-repeat padding-box;
      opacity: 1;
    }

    &:not(:first-child) {
      margin-top: 14px;
    }

    & > img {
      width: 60px;
      object-fit: cover;
      margin-bottom: 10px;
    }

    & > span {
      overflow-wrap: break-word;
      width: 100%;
    }
  }

  &__file-options {
    position: absolute;
    top: -3px;
    right: -3px;
    margin: -5px;
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;

    & > img {
      width: 20px;
    }
  }
}

.instruction {
  position: absolute;
  display: block;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
}

.process-info {
  margin-top: 20px;
}

.hide-files .p-fileupload-content {
  display: none;
}

.manual-upload-header {
  display: flex;
  flex-direction: row;

  &__load-info {
    margin-top: 20px;
    width: 100%;
    margin-bottom: 20px;
  }
}
.files-container {
  display: flex;
  align-items: baseline;
}
</style>
