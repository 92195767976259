<template>
  <div class="dropdown-tags">
    <b-dropdown
      dropleft
      variant="link"
      toggle-class="text-decoration-none"
      no-caret
      @hidden="filterTag = ''"
      text="Button text via Prop"
      size="sm"
      :disabled="disableTags"
      :id="'dropdown-form-tags' + emailIndex"
    >
      <template #button-content>
        <div
          v-for="(selectedTag, index) in selectedLabel"
          :key="selectedTag + emailIndex + index"
          class="dropdown-button-slot"
        >
          <div class="dropdown-button-slot__content">
            <b-icon
              icon="tag-fill"
              :style="{ color: selectedTag.color }"
            ></b-icon>
            {{ selectedTag.name }}
          </div>
        </div>
      </template>
      <b-dropdown-form>
        <b-overlay :show="showOverlay" rounded="sm">
          <b-form-input
            class="dropdown-search"
            v-model="filterTag"
            placeholder="Search tag..."
            autocomplete="off"
            size="sm"
          ></b-form-input>
          <b-dropdown-divider></b-dropdown-divider>
          <template v-for="(labelOption, index) in labelOptions">
            <div
              v-if="labelOption.name != 'NO LABEL'"
              :key="index"
              class="dropdown-item"
            >
              <Checkbox
                :id="labelOption.name + emailIndex + index"
                name="tag"
                :value="labelOption"
                v-model="selectedLabel"
                @input="checkTag"
                class="dropdown-item__checkbox"
              />
              <label
                :for="'label' + emailIndex + index"
                class="dropdown-item__label"
              >
                <b-icon
                  icon="tag-fill"
                  :style="'color:' + labelOption.color"
                ></b-icon>
                {{ labelOption.name }}
              </label>
            </div>
          </template>
        </b-overlay>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>
import Checkbox from "primevue/checkbox";

export default {
  name: "DropdownTags",
  components: {
    Checkbox,
  },
  props: {
    emailInfo: {
      type: Object,
      required: true,
    },
    labelOptionsList: {
      type: Array,
      required: true,
    },
    selectedLabelList: {
      type: Array,
      required: true,
    },
    emailIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      labelOptions: null,
      selectedLabel: null,
      filterTag: "",
      selectedLabelCopy: null,
      showOverlay: false,
      emailInfoCopy: null,
      disableTags: false,
    };
  },
  watch: {
    filterTag() {
      let completeLabelOptions = this.$store.getters["dbTags/showTags"];
      if (this.filterTag == "") {
        this.labelOptions = completeLabelOptions;
      } else {
        let filterText = this.filterTag.toUpperCase();
        let newArrayTags = completeLabelOptions.filter((label) =>
          label.name.includes(filterText)
        );
        this.labelOptions = newArrayTags;
      }
    },
    "$route.path.params.pathName": function () {
      if (this.$route.params.pathName == "posted") {
        this.disableTags = true;
      } else {
        this.disableTags = false;
      }
    },
  },
  created() {
    this.labelOptions = this.labelOptionsList;
    this.selectedLabel = this.selectedLabelList;
    this.selectedLabelCopy = this.selectedLabel;
    this.emailInfoCopy = this.emailInfo;
    if (this.$route.params.pathName == "posted") {
      this.disableTags = true;
    } else {
      this.disableTags = false;
    }
  },
  methods: {
    async checkTag() {
      this.showOverlay = true;
      if (this.selectedLabel.length > this.selectedLabelCopy.length) {
        for (let i in this.selectedLabel) {
          let selectedTag = this.selectedLabelCopy.find(
            (label) => label.id == this.selectedLabel[i].id
          );
          if (selectedTag == undefined) {
            let body = {
              email_id: this.emailInfoCopy.id,
              tag_id: this.selectedLabel[i].id,
            };
            let response = await this.$store.dispatch(
              "emailXTag/createEmailXTag",
              body
            );
            if (response.status && response.status != 201) {
              this.selectedLabel = this.selectedLabelCopy;
            } else {
              this.emailInfoCopy.tags = response.data;
              let validate = await this.validateNoLabel();
              if (validate) {
                if (
                  this.$route.params.pathName == "queue" ||
                  this.$route.params.pathName == "ocr-processes"
                ) {
                  let idEmail = this.$route.params.id;
                  if (idEmail) {
                    this.$emit("removeEmail", this.emailInfo.id);
                  } else {
                    this.$emit("removeEmail");
                  }
                }
              }
              this.selectedLabelCopy = this.selectedLabel;
              this.$store.commit("emailXTag/setCreateEmailTag", this.emailInfo.id)
            }
            break;
          }
        }
      } else {
        for (let i in this.selectedLabelCopy) {
          let selectedTag = this.selectedLabel.find(
            (label) => label.id == this.selectedLabelCopy[i].id
          );
          if (selectedTag == undefined) {
            let tagId = this.emailInfoCopy.tags.find(
              (tag) => tag.tag_id == this.selectedLabelCopy[i].id
            );
            let removeTag = await this.removeTag(tagId.id);
            if (removeTag.status == 200) {
              await this.validateEmptyTags();
            }
            break;
          }
        }
      }
      this.showOverlay = false;
    },
    async removeTag(tagId) {
      let response = await this.$store.dispatch(
        "emailXTag/removeEmailXTag",
        tagId
      );
      if (response.status && response.status != 200) {
        this.selectedLabel = this.selectedLabelCopy;
      } else {
        this.emailInfoCopy.tags = response.data;
        this.selectedLabelCopy = this.selectedLabel;
      }
      return response;
    },
    async validateNoLabel() {
      let validate = false;
      for (let i in this.selectedLabel) {
        if (this.selectedLabel[i].name != "NO LABEL") {
          validate = true;
        }
      }
      if (validate) {
        let noLabel = this.selectedLabel.find(
          (label) => label.name == "NO LABEL"
        );
        if (noLabel != undefined) {
          let tagId = this.emailInfoCopy.tags.find(
            (tag) => tag.tag_id == noLabel.id
          );
          await this.removeTag(tagId.id);
          for (let i in this.selectedLabel) {
            if (this.selectedLabel[i].name == "NO LABEL") {
              this.selectedLabel.splice(i, 1);
              break;
            }
          }
        }
      }
      return validate;
    },
    async validateEmptyTags() {
      let validate = false;
      if (this.selectedLabel.length == 0) {
        let tagId = this.labelOptions.find((label) => label.name == "NO LABEL");
        let body = {
          email_id: this.emailInfoCopy.id,
          tag_id: tagId.id,
        };
        let response = await this.$store.dispatch(
          "emailXTag/createEmailXTag",
          body
        );
        if (response.status == 201) {
          this.$emit("removeEmail");
        }
      }
      return validate;
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown-button-slot {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__content {
    display: flex;
    flex-direction: row;
    padding: 5px;
  }
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 15px;

  &__label {
    margin: 0px 0px 0px 7px;
    font-size: 13px;
  }
}

::v-deep .b-dropdown-form {
  padding: 0px 10px;
}

::v-deep .dropdown-menu {
  max-height: 500px !important;
  min-width: 250px;
  overflow-y: scroll;
  @include box-shadow;

  &::-webkit-scrollbar-track {
    @extend %scrollbar-track;
  }

  &::-webkit-scrollbar {
    @extend %scrollbar;
  }

  &::-webkit-scrollbar-thumb {
    @extend %scrollbar-thumb;
  }
}
</style>
