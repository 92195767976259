<template>
  <div class="emails" v-if="showView">
    <div :key="renderKey">
      <template v-if="$route.params.pathName == 'queue'">
        <Splitter class="splitters-content">
          <SplitterPanel class="p-d-flex p-ai-center p-jc-center">
            <div class="splitter-table">
              <div class="splitter-table__content">
                <h1 class="text-center">
                  <strong>{{ title }}</strong>
                </h1>
                <hr />
                <ListTable />
              </div>
            </div>
          </SplitterPanel>
          <SplitterPanel class="p-d-flex p-ai-center p-jc-center">
            <div class="splitter-attachments">
              <div class="splitter-attachments__content">
                <AttachmentsViewer :key="renderAttachments" />
              </div>
            </div>
          </SplitterPanel>
        </Splitter>
      </template>
      <template v-else>
        <div class="emails__table">
          <h1 class="text-center">
            <strong>{{ title }}</strong>
          </h1>
          <hr />
          <ListTable />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Splitter from "primevue/splitter";
import SplitterPanel from "primevue/splitterpanel";
import ListTable from "./ListTable";
import AttachmentsViewer from "./attachments/AttachmentsViewer";
import { mapState } from "vuex";

export default {
  name: "Emails",
  components: {
    Splitter,
    SplitterPanel,
    ListTable,
    AttachmentsViewer
  },
  data() {
    return {
      title: "",
      renderKey: 0,
      showView: false,
      renderAttachments: 0
    };
  },
  watch: {
    "$route.path": function(newVal, oldVal) {
      let newRoute = newVal.split("/")[2];
      let oldRoute = oldVal.split("/")[2];
      this.getTitle();
      if (newRoute != oldRoute) {
        this.renderKey += 1;
      }
    },
    sentToOcrFlag() {
      this.renderAttachments++;
      this.renderAttachments++;
    }
  },
  computed: {
    ...mapState({ sentToOcrFlag: state => state.ocr.sentToOcrFlag })
  },
  created() {
    if (this.$route.path != "/payments/settings") {
      this.showView = true;
      this.getTitle();
    }
  },
  methods: {
    getTitle() {
      let path = this.$route.params.pathName;
      if (path == "queue") {
        this.title = "Emails in Queue";
      } else if (path == "pending") {
        this.title = "Pending Emails";
      } else if (path == "doubtful") {
        this.title = "Doubtful Emails";
      } else if (path == "in-dispute") {
        this.title = "Emails in Dispute";
      } else if (path == "failed") {
        this.title = "Failed Emails";
      } else if (path == "posted") {
        this.title = "Posted Emails";
      } else if (path == "deleted") {
        this.title = "Deleted Emails";
      } else if (path == "ocr-processes") {
        this.title = "OCR Processes";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.emails {
  @include h1-font-size;
  &__table {
    margin-top: 30px;
    margin-right: 1%;
  }
}

.splitter-table {
  width: 100%;
  @extend %splitter-content;
  &__content {
    margin: 20px 15px 0px 0px;
  }
}

.splitter-attachments {
  width: 100%;
  @extend %splitter-content;
  &__content {
    margin: 20px 1% 0px 15px;
  }
}

.splitters-content {
  height: 100vh;
}

::v-deep {
  .p-splitter {
    border: none;
  }
}

::v-deep .p-splitter .p-splitter-gutter {
  background-color: $color-white;
}

::v-deep .p-splitter .p-splitter-gutter .p-splitter-gutter-handle {
  background-color: transparent;
}
</style>
