const fieldsTable = [
  {
    key: "actions",
    label: "ACTIONS"
  },
  {
    key: "date",
    label: "ARRIVAL DATE"
  },
  {
    key: "msg_from",
    label: "MESSAGE FROM"
  },
  {
    key: "subject",
    label: "SUBJECT"
  },
  {
    key: "label",
    label: "LABEL"
  }
];

export default fieldsTable;
