<template>
  <div class="attachments">
    <div class="attachments__header">
      <b-button
        v-if="!manualUpload"
        class="create-process"
        size="sm"
        @click="createProcess"
        >Create process</b-button
      >
      <div v-else></div>
      <div class="right-buttons">
        <div v-if="!manualUpload" class="right-buttons__show-email">
          <b-overlay :show="thereAreImages" rounded="sm" spinner-small>
            <b-button
              :disabled="imagesObtained"
              class="right-buttons__show-images"
              @click="getImages"
              >Show Images</b-button
            >
          </b-overlay>
          <b-button
            @click="showEmailContent"
            size="sm"
            v-b-toggle.collapse-email
            >Show Email</b-button
          >
        </div>
        <b-button class="right-buttons__send"
          ><i class="fas fa-paper-plane"></i> send</b-button
        >
        <b-button @click="sendToOcr" class="right-buttons__send-float"
          ><i class="fas fa-paper-plane"></i> Send</b-button
        >
      </div>
    </div>
    <b-collapse id="collapse-email">
      <b-card v-html="htmlBodyEmail"> </b-card>
    </b-collapse>
    <div class="attachments__upload">
      <b-tabs>
        <template v-slot:tabs-end>
          <b-nav-item role="presentation" @click.prevent="newTab"
            ><b>+</b></b-nav-item
          >
        </template>
        <template v-slot:empty>
          <div>There are no open tabs</div>
        </template>
        <template v-for="tab in tabs">
          <b-tab
            @click="changeTab(tab)"
            :title="'Load' + tab"
            :key="'dyn-tab-' + tab"
          >
            <template v-slot:title>
              <span>{{ tab }}</span
              ><button
                type="button"
                class="close"
                aria-label="Close"
                @click="deleteTab(tab)"
              >
                <span aria-hidden="true" class="delete-tab">&times;</span>
              </button>
            </template>
            <template>
              <div class="load-upload" v-if="manualUpload">
                <ManualUpload
                  class="load-upload__manual"
                  @uploadFile="uploadFile"
                  :tabSelected="tabSelected"
                  :load="tab"
                  @reloadPdfViewer="keyRender += 1"
                />
                <pdfViewer
                  :key="keyRender"
                  class="load-upload__pdf-viewer"
                  :file="file"
                  :tabSelectedRender="tab"
                />
              </div>
              <div class="load-upload" v-else>
                <div v-show="filesAreLoaded" class="spinner-container">
                  <b-spinner label="Spinning" variant="primary"></b-spinner>
                </div>
                <EmailUpload
                  v-show="!filesAreLoaded"
                  class="load-upload__manual"
                  @uploadFile="uploadFile"
                  @reloadPdfViewer="keyRender += 1"
                  @imagesLoaded="imagesLoaded"
                  :tabSelected="tabSelected"
                  :load="tab"
                  :images="images"
                />
                <pdfViewer
                  :key="keyRender"
                  class="load-upload__pdf-viewer"
                  :file="file"
                  :tabSelectedRender="tab"
                />
              </div>
            </template>
          </b-tab>
        </template>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import ManualUpload from "./manualUpload/ManualUpload";
import EmailUpload from "./emailUpload/EmailUpload";
import pdfViewer from "./PdfViewer";
import { mapState } from "vuex";

export default {
  name: "Attachments",
  components: {
    ManualUpload,
    EmailUpload,
    pdfViewer,
  },
  data() {
    return {
      numberOfTabs: 1,
      tabs: [1],
      manualUpload: true,
      file: null,
      keyRender: 0,
      htmlBodyEmail: null,
      tabSelected: 1,
      optionsLoads: [1],
      infoFileFromEmail: null,
      images: null,
      thereAreImages: false,
      imagesObtained: false,
      indexShowEmail: 0,
      filesAreLoaded: false,
    };
  },
  watch: {
    "$route.path": function () {
      this.verifyId();
      this.imagesObtained = false;
    },
    async showEmails() {
      this.filesAreLoaded = true;
      this.file = null;
      this.keyRender += 1;
      this.tabs = [1];
      this.indexShowEmail = true;
      this.htmlBodyEmail = null;
      this.$store.commit("dbLoads/setCleanLoadsById");
      this.infoFileFromEmail = this.$store.getters["dbFile/showDbFile"];
      for (let i in this.infoFileFromEmail) {
        let pushControl = true;
        if (this.infoFileFromEmail[i].load_id != null) {
          let response = await this.$store.dispatch(
            "dbLoads/loadsById",
            this.infoFileFromEmail[i].load_id
          );
          if (this.tabs[0] == 1) {
            this.tabs = [];
            this.tabs.push(response.shp);
          } else {
            if (this.tabs.length > 0) {
              for (let j in this.tabs) {
                if (response.shp == this.tabs[j]) {
                  pushControl = false;
                  break;
                }
              }
              if (pushControl) {
                this.tabs.push(response.shp);
              }
            }
          }
        }
      }
      this.$store.commit("dbFile/setLoadsOptions", this.tabs);
    },
    async thereAreFilesFlag() {
      await this.sleep(1000);
      this.filesAreLoaded = false;
      this.changeTab(this.tabs[0]);
    },
  },
  computed: {
    ...mapState({
      showEmails: (state) => state.emails.showEmails,
      bodyEmails: (state) => state.emails.getEmailContent,
      thereAreFilesFlag: (state) => state.bucket.thereAreFilesFlag,
    }),
  },
  created() {
    this.verifyId();
  },
  methods: {
    async showEmailContent() {
      if (this.indexShowEmail) {
        let uidMsg = await this.$store.dispatch(
          "emails/getEmailsById",
          this.$route.params.id
        );
        if (uidMsg.uid_msg) {
          let params = {};
          params.uid_msg = uidMsg.uid_msg;
          if (uidMsg.uid_msg != null || uidMsg.uid_msg != "") {
            await this.$store.dispatch("emails/getEmailsContent", params);
          }
        }
        let pathEmail = await this.$store.getters["emails/showEmailContent"];
        if (pathEmail.path) {
          let params = {};
          params.path = pathEmail.path;
          this.htmlBodyEmail = await this.$store.dispatch(
            "bucket/getHtmlbody",
            params
          );
          this.indexShowEmail = false;
        }
      }
    },
    changeTab(tab) {
      this.tabSelected = tab;
      this.$store.commit("dbFile/setTabState", tab);
    },
    async uploadFile(file) {
      this.file = file;
    },
    createProcess() {
      this.$store.commit("dbFile/setCleanFiles");
      this.$store.commit("dbLoads/setCleanLoadsInfo");
      this.$store.commit("dbFile/setCleanCreatedFiles");
      this.$store.commit("dbFile/setCleanLoadOptions");
      this.$store.commit("dbFile/cleanFilesArrays");
      this.$store.commit("emails/setShowEmails", false);
      this.manualUpload = !this.manualUpload;
      if (this.manualUpload) {
        let path = this.$route.params.pathName;
        this.$router
          .push({ name: "Emails", params: { pathName: path } })
          .catch((err) => {
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              // logError(err);
            }
          });
      }
    },
    newTab() {
      if (this.tabs.length > 0) {
        this.numberOfTabs = this.tabs.length + 1;
      } else {
        this.numberOfTabs = 1;
        this.$store.commit("dbFile/setTabState", this.numberOfTabs);
      }
      this.optionsLoads = this.$store.getters["dbFile/showLoadOptions"];
      this.tabs.push(this.numberOfTabs);
      if (!this.$route.params.id) {
        this.optionsLoads.push(this.numberOfTabs);
      }
      this.$store.commit("dbFile/setLoadsOptions", this.optionsLoads);
    },
    deleteTab(tab) {
      let deletedTabIndex;
      let lastTabDeleted = this.tabs[this.tabs.length - 1] === tab;
      for (let i in this.tabs) {
        if (this.tabs[i] == tab) {
          deletedTabIndex = i;
          this.tabs.splice(i, 1);
          this.optionsLoads.splice(i, 1);
        }
      }
      if (lastTabDeleted) {
        deletedTabIndex = this.tabs.length - 1;
      }
      this.tabSelected = this.tabs[deletedTabIndex];
      this.$store.commit("dbFile/setTabState", this.tabs[deletedTabIndex]);
      this.$store.commit("dbFile/setLoadsOptions", this.optionsLoads);
      this.$store.commit("dbFile/setDeletedTabFiles", tab);
    },
    verifyId() {
      if (this.$route.params.id) {
        this.manualUpload = false;
      } else {
        this.manualUpload = true;
      }
    },
    async sendToOcr() {
      await this.$store.dispatch("ocr/sendToOcr", this.$route.params.id);
    },
    async getImages() {
      this.thereAreImages = true;
      let emailId = this.$route.params.id;
      let uidMsg = await this.$store.dispatch("emails/getEmailsById", emailId);
      if (uidMsg.uid_msg) {
        let imagesPath = await this.$store.dispatch(
          "emails/getEmailsImages",
          uidMsg.uid_msg
        );
        this.images = imagesPath.images_path;
      }
      this.imagesObtained = true;
    },
    imagesLoaded() {
      this.thereAreImages = false;
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
  },
};
</script>

<style lang="scss" scoped>
%flex-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.delete-tab {
  @include close-button($color-white);
}

.attachments {
  width: 100%;

  &__header {
    @extend %flex-box;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 20px;
  }

  &__upload {
    margin-top: 8px;
  }
}

.load-upload {
  width: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;

  &__manual {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &__pdf-viewer {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.create-process {
  margin-top: 5px;
  @include primary-button;
}

.right-buttons {
  @extend %flex-box;
  justify-content: flex-end;
  margin-top: 5px;

  &__show-email {
    display: flex;
    margin-right: 10px;

    button {
      @include secondary-button;
      font-size: 14px;
    }
  }
  &__show-images {
    margin-right: 10px;
  }

  &__send {
    @include primary-button;
    visibility: hidden;
  }
  &__send-float {
    @include primary-button;
    position: fixed;
    z-index: 5;
    font-size: 14px;
  }
}

.spinner-container {
  display: flex;
  justify-content: center;
}

::v-deep .card {
  padding: 5px;
}
</style>
