<template>
  <div class="pdf-viewer">
    <template>
      <div v-for="(i, index) in numPages" :key="i">
        <div class="upload-types">
          <label class="upload-types__label">Set Page As: </label>
          <select
            v-model="pageTypeSelected[i - 1]"
            @change="pageSelected(i)"
            name="upload-types"
            class="upload-types__select"
          >
            <option value="select" selected>Select</option>
            <option
              :key="pageType.id + index"
              v-for="(pageType, index) in pageTypes"
              :value="pageType.name"
            >
              {{ pageType.name }}
            </option>
          </select>
        </div>
        <div class="page-content">
          <pdf v-if="src !== null" :src="src" :page="index + 1"> </pdf>
          <img
            v-if="imgSrc !== null"
            class="page-content-img__img"
            :src="imgSrc"
            alt=""
          />
        </div>
        <!-- <div class="page-content-img">
        </div> -->
      </div>
    </template>
  </div>
</template>

<script>
import pdf from "vue-pdf";
import { mapState } from "vuex";

export default {
  name: "pdfViewer",
  props: ["file", "tabSelectedRender"],
  components: {
    pdf,
  },

  data() {
    return {
      numPages: 0,
      src: null,
      imgSrc: null,
      page: 1,
      pageTypes: null,
      pageTypeSelected: [],
      loadId: null,
      schemeCommit: [],
      updateFileScheme: {
        tabSelected: 1,
        fileIdSelected: null,
        load_id: null,
        pages: []
      },
      commitControl: true,
      whileControl: true,
      fileIdSelected: null,
    };
  },

  watch: {
    file() {
      this.updateFileScheme.tabSelected =
        this.$store.getters["dbFile/showTabSelected"];
      if (this.updateFileScheme.tabSelected === this.tabSelectedRender) {
        if (this.file.type === "image") {
          this.numPages = 1;
          this.src = null;
          let reader = new FileReader();
          reader.readAsDataURL(this.file.src);
          reader.onloadend = () => {
            this.imgSrc = reader.result;
          };
        } else {
          this.imgSrc = null;
          this.src = pdf.createLoadingTask(this.file.src);
          this.src.promise.then((pdf) => {
            this.numPages = pdf.numPages;
          });
        }
      }
    },
    async stateFileIdSelected(fileIdSelected) {
      await this.sleep(300);
      let infoLoadId = this.$store.getters["dbLoads/showLoadIdInfo"];
      this.fileIdSelected = fileIdSelected;
      this.updateFileScheme.tabSelected =
        this.$store.getters["dbFile/showTabSelected"];
      for (let i in infoLoadId) {
        if (infoLoadId[i].tab == this.updateFileScheme.tabSelected) {
          this.updateFileScheme.load_id = infoLoadId[i].id;
        }
      }
      let infoEmailSelected =
        this.$store.getters["dbFile/showInfoEmailSelected"];
      this.pageTypeSelected = [];
      for (let i in this.schemeCommit) {
        if (this.schemeCommit[i].fileIdSelected == this.fileIdSelected) {
          this.commitControl = false;
        }
      }
      if (this.commitControl) {
        if (this.$route.params.id) {
          for (let i = 0; i < this.numPages; i++) {
            for (let j in infoEmailSelected.pages) {
              if (infoEmailSelected.pages[j].page == i + 1) {
                this.pageTypeSelected[i] = infoEmailSelected.pages[j].type;
              }
            }
          }
        } else {
          for (let i = 0; i < this.numPages; i++) {
            this.pageTypeSelected[i] = "select";
          }
        }
      }
      this.commitControl = true;
      if (this.updateFileScheme.pages.length > 0) {
        if (this.schemeCommit.length == 0) {
          this.schemeCommit.push(this.updateFileScheme);
        } else {
          for (let i in this.schemeCommit) {
            if (
              this.schemeCommit[i].fileIdSelected ==
              this.updateFileScheme.fileIdSelected
            ) {
              this.schemeCommit[i] = this.updateFileScheme;
              this.commitControl = false;
            }
          }
          if (this.commitControl) {
            this.schemeCommit.push(this.updateFileScheme);
          }
        }
        this.$store.commit("dbFile/setFilesSchemas", this.schemeCommit);
      }
      for (let i in this.schemeCommit) {
        if (this.schemeCommit[i].fileIdSelected == this.fileIdSelected) {
          for (let j = 0; j < this.numPages; j++) {
            this.pageTypeSelected[j] = this.schemeCommit[i].pages[j].type;
          }
        }
      }
      if (this.updateFileScheme.fileIdSelected != null) {
        this.updateFileScheme = {
          fileIdSelected: null,
          pages: [],
          load_id: null,
        };
      }
    },
  },
  computed: {
    ...mapState({
      stateFileIdSelected: (state) => state.dbFile.fileSelected,
    }),
  },
  async created() {
    this.pageTypes = await this.$store.dispatch("dbPageTypes/getPageTypes");
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    pageSelected() {
      this.updateFileScheme.pages = [];
      this.updateFileScheme.tabSelected =
        this.$store.getters["dbFile/showTabSelected"];
      let infoLoadId = this.$store.getters["dbLoads/showLoadIdInfo"];
      if (!this.updateFileScheme.load_id) {
        if (
          infoLoadId[infoLoadId.length - 1].tab ==
          this.updateFileScheme.tabSelected
        ) {
          this.updateFileScheme.load_id = infoLoadId[infoLoadId.length - 1].id;
        }
      }
      this.updateFileScheme.fileIdSelected =
        this.$store.getters["dbFile/showIdFileSelected"];
      for (let i = 0; i < this.pageTypeSelected.length; i++) {
        this.updateFileScheme.pages.push({
          page: i + 1,
          type: this.pageTypeSelected[i],
        });
      }
      this.$store.dispatch("dbFile/manualScheme", this.updateFileScheme);
      //Clear updateFileScheme
      this.updateFileScheme = {
        tabSelected: 1,
        fileIdSelected: null,
        load_id: null,
        pages: []
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.pdf-viewer {
  margin: 20px;
}

.upload-types {
  display: flex;
  flex-direction: column;
  margin: 30px 0px 20px 0px;

  &__label {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin: 0px;
    color: #1d1f1f;
    font-weight: bold;
  }

  &__select {
    background-color: #ffff;
    outline: none;
    padding: 5px 10px;
    color: #1d1f1f;
    height: 40px;
    border-radius: 4px;
    border: 1px solid #767676;
  }
}

.page-content-img {
  display: flex;
  justify-content: center;
  &__img {
    width: 100%;
  }
}
</style>
