<template>
  <div :key="keyRenderTable">
    <div class="list-table">
      <div class="list-table__date-start">
        <label for="datepicker-buttons">Start: </label>
        <b-form-datepicker
          id="datepicker-start"
          v-model="dateStart"
          reset-button
          close-button
          reset-value=""
          locale="en"
        ></b-form-datepicker>
      </div>
      <div class="list-table__date-end">
        <label for="datepicker-buttons">End: </label>
        <b-form-datepicker
          id="datepicker-end"
          v-model="dateEnd"
          reset-button
          close-button
          locale="en"
        ></b-form-datepicker>
      </div>
      <div class="list-table__tag-filter">
        <label for="datepicker-buttons" v-if="path == 'pending'"
          >Filter:
        </label>
        <Dropdown
          v-model="selectedTag"
          :options="tags"
          optionLabel="name"
          placeholder="Select a filter by tag"
          v-if="path == 'pending'"
          :filter="true"
          :showClear="true"
          @change="filterByTag"
          ><template #value="slotProps">
            <div class="value-tag" v-if="slotProps.value">
              <i
                class="fas fa-tag"
                :style="'color:' + slotProps.value.color"
              ></i>
              <div>{{ slotProps.value.name }}</div>
            </div>
            <span v-else>
              {{ slotProps.placeholder }}
            </span>
          </template></Dropdown
        >
      </div>
    </div>
    <b-table
      id="list-table"
      show-empty
      hover
      class="list-table__table"
      head-variant="light"
      stacked="md"
      :items="items"
      :per-page="0"
      :current-page="currentPage"
      :fields="fields"
      :busy="isBusy"
      @row-clicked="clickRow"
    >
      <template v-slot:table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner
            class="align-middle"
            aria-label="Loading table"
          ></b-spinner>
          <strong>Loading...</strong>
        </div>
      </template>
      <template v-slot:thead-top="data">
        <b-tr v-bind:key="data.columns">
          <b-th variant="dark" :colspan="colspan">
            <div class="table-header">
              <b-button
                variant="info"
                size="sm"
                @click="deleteEmail"
                v-if="path != 'deleted'"
                >Delete</b-button
              >
              <b-button
                class="table-header__restore"
                variant="info"
                size="sm"
                @click="restore"
                v-if="path == 'deleted'"
                >Restore</b-button
              >
              <b-button
                variant="info"
                size="sm"
                v-if="path == 'ocr-processes'"
                @click="sendToEmails(7)"
                >Send to In Dispute</b-button
              >
              <b-button
                variant="info"
                size="sm"
                v-else-if="path == 'in-dispute'"
                @click="sendToEmails(4)"
                >Send to OCR</b-button
              >
              <div class="table-header__search">
                <b-input-group size="sm">
                  <b-form-input
                    aria-label="Type here what you want to search in the list of pending quotes"
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search"
                    autocomplete="off"
                  >
                    Filtro {{ filter }}
                  </b-form-input>
                  <b-input-group-append>
                    <b-button
                      variant="info"
                      :disabled="!filter"
                      @click="filter = ''"
                      >Clear</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>
          </b-th>
        </b-tr>
      </template>
      <template v-slot:head(actions)="column">
        <b-form-checkbox
          :id="column.index"
          v-model="areAllSelected"
          name="checkbox-1"
          value="accepted"
          unchecked-value="not_accepted"
        >
        </b-form-checkbox>
      </template>
      <template v-slot:cell(actions)="row">
        <b-form-checkbox
          :id="'row' + row.index"
          v-model="isChecked[row.index]"
          name="select-row"
          value="accepted"
          unchecked-value="not_accepted"
        >
        </b-form-checkbox>
      </template>
      <template v-slot:cell(date)="row">
        {{ items[row.index].date | date }}
      </template>
      <template v-slot:cell(label)="row">
        <DropdownTags
          :labelOptionsList="labelOptions"
          :selectedLabelList="selectedLabel[row.index]"
          :emailIndex="row.index"
          :emailInfo="items[row.index]"
          @removeEmail="removeEmailEvent"
        />
      </template>
    </b-table>
    <div class="list-table__pagination">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        @change="callEmails('pagination')"
        class="my-0"
        aria-controls="items"
        last-number
      >
      </b-pagination>
    </div>
  </div>
</template>

<script>
import fieldsTable from "@/utils/listTable";
import DropdownTags from "@/components/DropdownTags";
import Dropdown from "primevue/dropdown";
import { mapState } from "vuex";

export default {
  name: "ListTable",
  components: {
    DropdownTags,
    Dropdown,
  },
  data() {
    return {
      items: [],
      fields: null,
      currentPage: 1,
      skip: 0,
      totalRows: 0,
      perPage: 20,
      isBusy: false,
      colspan: 5,
      areAllSelected: "not_accepted",
      isChecked: [],
      labelOptions: null,
      selectedLabel: [],
      filter: "",
      dateStart: "",
      dateEnd: "",
      path: null,
      keyRenderTable: 0,
      queueStatus: {
        queue: 3,
        pending: 5,
        doubtful: 6,
        "in-dispute": 7,
        failed: 8,
        posted: 9,
        "ocr-processes": 4,
      },
      searchByPathFlag: false,
      isEmailInCurrentQueue: true,
      tags: [],
      selectedTag: null,
    };
  },
  watch: {
    areAllSelected() {
      if (this.areAllSelected == "accepted") {
        for (let i in this.items) {
          this.isChecked[i] = "accepted";
        }
      } else if (this.areAllSelected == "not_accepted") {
        for (let i in this.items) {
          this.isChecked[i] = "not_accepted";
        }
      }
    },
    "$route.path": function () {
      this.isBusy = true;
      if (this.$route.params.id) {
        let id = this.$route.params.id;
        this.setRowColor(id);
      } else {
        for (let i in this.items) {
          this.items[i]._rowVariant = "none";
        }
      }
      this.isBusy = false;
    },
    dateStart() {
      this.callEmails();
    },
    dateEnd() {
      this.callEmails();
    },
    filter() {
      if (this.filter != "") {
        this.totalRows = 20;
        this.callEmails();
      } else {
        this.count();
        this.callEmails();
      }
    },
    sentToOcrFlag() {
      this.$router.push({ name: "Emails" });
      this.callEmails();
    },
    createEmailTags(emailId) {
      let route = this.$route.params.pathName;
      if (route == "queue") {
        for (let i in this.items) {
          if (this.items[i].id == emailId) {
            this.items.splice(i, 1);
          }
        }
        this.keyRenderTable += 1;
      }
    },
  },
  computed: {
    ...mapState({
      sentToOcrFlag: (state) => state.ocr.sentToOcrFlag,
      notification: (state) => state.notification,
      createEmailTags: (state) => state.emailXTag.createEmailTag,
    }),
  },
  async created() {
    this.isBusy = true;
    this.fields = fieldsTable;
    if (this.$route.params.id) {
      this.path = await this.$route.params.pathName;
      let status = this.queueStatus[this.path];
      let emailInfo = await this.$store.dispatch(
        "emails/getEmailsById",
        this.$route.params.id
      );
      if (emailInfo.status_email_id !== status) {
        this.isEmailInCurrentQueue = false;
        this.$router.push({ name: "Emails" });
      }
    }
    await this.count();
    await this.callEmails();
    if (this.$route.params.id) {
      for (let i in this.items) {
        let id = this.$route.params.id;
        if (this.items[i].id == id) {
          this.clickRow(this.items[i]);
        }
      }
    }
    this.isBusy = false;
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    async callEmails(origin = "notPagination") {
      this.isBusy = true;
      await this.sleep(100);
      this.path = await this.$route.params.pathName;
      let status = this.queueStatus[this.path];
      let params = {};
      if (this.path == "deleted") {
        params.deleted = true;
      } else {
        params.deleted = false;
        params.status_email_id = status;
        if (this.path == "pending") {
          params.second_status_email_id = 10;
        }
      }
      if (this.dateStart != null && this.dateStart != "") {
        params.date_start = this.dateStart + "T00:00";
      }
      if (this.dateEnd != null && this.dateEnd != "") {
        params.date_end = this.dateEnd + "T00:00";
      }
      if (this.filter != null && this.filter != "") {
        params.search = this.filter;
      }
      if (this.selectedTag != null && this.selectedTag != "") {
        params.tag_id = this.selectedTag.id;
      }
      params.limit = 20;
      if (this.filter != null && this.filter != "") {
        params.skip = 0;
      } else {
        params.skip = (this.currentPage - 1) * this.perPage;
      }
      this.items = [];
      this.isChecked = [];
      let response = await this.$store.dispatch(
        "emails/getEmailsInQueue",
        params
      );
      if (
        this.isEmailInCurrentQueue === true &&
        !this.searchByPathFlag &&
        this.$route.params.id
      ) {
        let currentPageCounter = 1;
        let emailId;
        while (!emailId) {
          emailId = response.data.find((el) => el.id == this.$route.params.id);
          if (emailId) {
            break;
          }
          params.skip += 20;
          response = await this.$store.dispatch(
            "emails/getEmailsInQueue",
            params
          );
          if (response.data.length === 0) {
            break;
          }
          currentPageCounter++;
        }
        this.currentPage = currentPageCounter;
      }
      this.items = response.data;
      await this.showTags();
      for (let i in this.items) {
        this.selectedLabel[i] = [];
        this.isChecked[i] = "not_accepted";
        for (let j in this.labelOptions) {
          for (let tag in this.items[i].tags) {
            if (this.items[i].tags[tag].tag_id == this.labelOptions[j].id) {
              this.selectedLabel[i].push(this.labelOptions[j]);
            }
          }
        }
      }
      await this.sleep(400);
      this.isBusy = false;
      this.keyRenderTable += 1;
      if (this.$route.params.id) {
        this.setRowColor(this.$route.params.id);
      }
      this.searchByPathFlag = true;
      if (origin === "pagination") {
        this.$router.push({ name: "Emails" });
      }
    },
    async showTags() {
      let response = await this.$store.dispatch("dbTags/getTags");
      this.labelOptions = response.data;
      this.tags = this.labelOptions;
    },
    async removeEmailEvent(event) {
      this.isBusy = true;
      this.fields = fieldsTable;
      await this.count();
      await this.callEmails();
      let pathName = this.$route.params.pathName;
      if (event && this.$route.params.id != event) {
        let emailId = this.$route.params.id;
        this.$router
          .push({
            name: "Emails",
            params: { pathName: pathName, id: emailId },
          })
          .catch((err) => {
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              // logError(err);
            }
          });
      } else {
        this.$router
          .push({
            name: "Emails",
            params: { pathName: pathName },
          })
          .catch((err) => {
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              // logError(err);
            }
          });
      }
      this.isBusy = false;
    },
    async count() {
      this.path = this.$route.params.pathName;
      let status = this.queueStatus[this.path];
      let params = {};
      if (this.path == "deleted") {
        params.deleted = true;
      } else {
        params.deleted = false;
      }
      // if (this.path == "queue") {
      //   params.tag_id = 1;
      // }
      params.limit = 20;
      params.skip = (this.currentPage - 1) * this.perPage;
      params.status_email_id = status;
      let response = await this.$store.dispatch("emails/getCount", params);
      this.totalRows = response.data.emails;
    },
    async clickRow(item) {
      this.$store.commit("emails/setShowEmails", false);
      this.isBusy = true;
      let path = this.$route.params.pathName;
      if (
        path == "ocr-processes" ||
        path == "in-dispute" ||
        path == "posted" ||
        path == "pending"
      ) {
        localStorage.setItem("lastPath", path);
        this.$router
          .push({
            name: "InfoEmail",
            params: { pathName: path, id: item.id },
          })
          .catch((err) => {
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              // logError(err);
            }
          });
      } else if (path == "queue") {
        this.$router
          .push({
            name: "Emails",
            params: { pathName: path, id: item.id },
          })
          .catch((err) => {
            if (
              err.name !== "NavigationDuplicated" &&
              !err.message.includes(
                "Avoided redundant navigation to current location"
              )
            ) {
              // logError(err);
            }
          });
        let params = {};
        params.email_id = item.id;
        this.$store.commit("dbFile/setCleanDbFiles");
        await this.$store.dispatch("dbFile/getDbFiles", params);
        this.$store.commit("emails/setShowEmails", true);
        this.$store.commit("dbFile/setCleanCreatedFiles");
        this.$store.commit("dbLoads/setCleanLoadsInfo");
        this.$store.commit("dbFile/setCleanLoadOptions");
        this.$store.commit("dbFile/cleanFilesArrays");
        this.$store.commit("bucket/cleanImages");
      }
      this.isBusy = false;
      this.setRowColor(item.id);
    },
    setRowColor(id) {
      for (let i in this.items) {
        if (this.items[i].id == id) {
          this.items[i]._rowVariant = "info";
        } else {
          this.items[i]._rowVariant = "none";
        }
      }
    },
    selectLabel(indexItem, indexLabel) {
      this.selectedLabel[indexItem] = this.labelOptions[indexLabel];
    },
    async deleteEmail() {
      let ids = [];
      for (let i in this.isChecked) {
        if (this.isChecked[i] == "accepted") {
          ids.push(this.items[i].id);
          this.isChecked[i] = "not_accepted";
        }
      }
      if (this.areAllSelected == "accepted") {
        this.areAllSelected = "not_accepted";
      }
      for (let i in ids) {
        await this.$store.dispatch("emails/deleteEmails", ids[i]);
        this.callEmails();
      }
    },
    async sendToEmails(params) {
      let ids = [];
      for (let i in this.isChecked) {
        if (this.isChecked[i] == "accepted") {
          ids.push(this.items[i].id);
          this.isChecked[i] = "not_accepted";
        }
      }
      if (this.areAllSelected == "accepted") {
        this.areAllSelected = "not_accepted";
      }
      for (let i in ids) {
        await this.$store.dispatch("emails/sendToEmails", {
          id: ids[i],
          body: {
            status_email_id: params,
          },
        });
        this.callEmails();
      }
    },
    async restore() {
      let ids = [];
      for (let i in this.isChecked) {
        if (this.isChecked[i] == "accepted") {
          ids.push(this.items[i].id);
          this.isChecked[i] = "not_accepted";
        }
      }
      if (this.areAllSelected == "accepted") {
        this.areAllSelected = "not_accepted";
      }
      for (let i in ids) {
        await this.$store.dispatch("emails/restoreEmails", ids[i]);
        this.callEmails();
      }
    },
    filterByTag() {
      this.callEmails();
    },
  },
};
</script>

<style lang="scss" scoped>
.list-table {
  &__filter-date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__table {
    margin-top: 30px;
    @include box-shadow;
  }

  &__pagination {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .tag-filter {
    display: flex;
    flex-direction: column;
    width: 20%;
    margin: 0px;
  }
}

.date-start {
  width: 20%;
  margin-right: 20px;
}

.date-end {
  width: 20%;
  margin-left: 20px;
}

.value-tag {
  display: flex;
  flex-direction: row;
}

.table .thead-light th {
  color: $color-light;
  background-color: $color-background-dark;
  border: none;
  border-radius: 6px 6px 0px 0px;
  padding: 20px 10px;
}

.b-table {
  cursor: pointer;
  border-radius: 6px;
  font-size: 13.5px;
}

.table-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &__search {
    width: 70%;
  }

  &__restore {
    margin-right: 20%;
  }
}

.input-group .form-control:not(:last-child),
.input-group .custom-select:not(:last-child) {
  border: none !important;
  color: $color-light;
  background-color: hsla(0, 0%, 100%, 0.125) !important;
}

.input-group .form-control::placeholder {
  color: rgba(171, 178, 185, 0.9) !important;
}

.dropdown-button-slot {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__content {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
  }
}

.dropdown-item {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 15px;

  &__label {
    margin: 0px 0px 0px 7px;
    font-size: 13px;
  }
}

::v-deep .b-dropdown-form {
  padding: 0px 10px;
}

::v-deep .dropdown-menu {
  max-height: 300px;
  min-width: 250px;
  overflow-y: scroll;
  @include box-shadow;

  &::-webkit-scrollbar-track {
    @extend %scrollbar-track;
  }

  &::-webkit-scrollbar {
    @extend %scrollbar;
  }

  &::-webkit-scrollbar-thumb {
    @extend %scrollbar-thumb;
  }
}

.list-table {
  display: flex;
  justify-content: space-between;

  &__date-start {
    width: 40%;
    margin: 0px;
  }

  &__date-end {
    width: 40%;
    margin: 0px;
  }

  &__tag-filter {
    display: flex;
    flex-direction: column;
    width: 15%;
    margin: 0px;
  }
}
@media (max-width: 700px) {
  .list-table {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    &__date-start {
      width: 100%;
    }

    &__date-end {
      width: 100%;
    }

    &__tag-filter {
      width: 100%;
    }
  }
}
</style>
