<template>
  <div class="shp-search">
    <b-form @submit.stop.prevent>
      <div class="shp-search__input">
        <b-form-input
          :list="'my-list-id' + load"
          :state="shpExists"
          autocomplete="off"
          v-model="shpCode"
        ></b-form-input>
        <datalist :id="'my-list-id' + load">
          <option :key="code + index" v-for="(code, index) in shpCodes">
            {{ code }}
          </option>
        </datalist>
        <b-form-invalid-feedback :state="shpExists">
          Please select a valid SHP Code.
        </b-form-invalid-feedback>
        <b-form-valid-feedback :state="shpExists">
          Looks Good.
        </b-form-valid-feedback>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Shp",
  props: ["load"],
  data() {
    return {
      shpCode: "",
      shpId: "",
      shpCodes: [],
      shpExists: false,
    };
  },
  watch: {
    async shpCode() {
      this.shpCodes = [];
      if (this.shpCode.length >= 4) {
        this.shpCode = this.shpCode.toUpperCase();
        if (
          this.shpCode[0] == "S" &&
          this.shpCode[1] == "H" &&
          this.shpCode[2] == "P"
        ) {
          let response = await this.$store.dispatch(
            "crmLoads/getShpCode",
            this.shpCode
          );
          for (let i in response) {
            this.shpCodes.push(response[i].name);
          }
        } else {
          this.shpCode = "SHP" + this.shpCode;
        }
        if (this.shpCodes.length > 0) {
          let shpAux = this.shpCodes.find((shp) => shp == this.shpCode);
          if (shpAux != undefined) {
            this.shpExists = true;
            this.shpSend();
          } else {
            this.shpExists = false;
          }
        }
      } else {
        this.shpExists = false;
      }
    },
    shpCodeObject(shpCode) {
      for (let i in shpCode) {
        if (shpCode[i].name == this.shpCode) {
          this.shpCode = shpCode[i].name;
          this.shpId = shpCode[i].id;
          this.shpCodes = [];
        }
      }
    },
    tabSelected(tabState) {
      if (this.load === tabState) {
        let infoLoads = this.$store.getters["dbLoads/showInfoLoadById"];
        if (this.shpCode === "") {
          for (let i in infoLoads) {
            if (infoLoads[i].shp === tabState) {
              this.shpCode = infoLoads[i].shp;
              let shpInfo = {};
              shpInfo.shpCode = this.shpCode;
              shpInfo.id = infoLoads[i].salesforce_id;
              shpInfo.tabSelected = tabState;
              this.$emit("shpSend", shpInfo);
              break;
            }
          }
        }
      }
    },
    loadsCreate() {
      this.assignLoadId(this.loadsCreate);
    },
  },
  computed: {
    ...mapState({
      shpCodeObject: (state) => state.crmLoads.shpCodesObject,
      infoLoadsById: (state) => state.dbLoads.loadById,
      loadsCreate: (state) => state.dbLoads.loadsCreate,
      tabSelected: (state) => state.dbFile.tabState,
    }),
  },
  methods: {
    async shpSend() {
      let tabSelected = this.$store.getters["dbFile/showTabSelected"];
      let shpInfo = {};
      shpInfo.shpCode = this.shpCode;
      shpInfo.id = this.shpId;
      shpInfo.tabSelected = tabSelected;
      this.$emit("shpSend", shpInfo);
    },
    async assignLoadId(load) {
      if (this.tabSelected === this.load) {
        let params = {};
        let dbFiles = [...this.$store.getters["dbFile/showDbFile"]];
        let filesTransfer = [
          ...this.$store.getters["dbFile/showFilesTransfer"],
        ];
        let files = filesTransfer.map((item, index) => {
          if (item.id === dbFiles[index].id) {
            return {
              name: item.name,
              id: item.id,
              load_id: dbFiles[index].load_id,
              tab: item.tab,
            };
          }
        });
        for (let file of files) {
          if (file.tab == this.load) {
            params.fileIdSelected = file.id;
            params.load_id = load.id;
            await this.$store.dispatch("dbFile/manualScheme", params);
          }
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.shp-search {
  width: 100%;

  &__input {
    margin: 0% 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  label {
    margin-right: 20px;
    margin-bottom: 0px;
  }
}
</style>
